<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>
      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Text-to-Speech')" name="tts_settings">
          <el-form-item
            prop="watson_speech_node.data.user_input_nodes_setting.data.prompt_text"
            :label="__('Prompt')"
          >
            <input-variable-popper
              :value="promptText"
              is-content-editable
              @input="updateText('prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-watson_speech"
            />
            <audio-player
              class="audio-player"
              :disabled="!promptText"
              @get-preview="
                generateAudio(
                  'promptText',
                  'generatingAudioForPrompt',
                  'promptAudioFile',
                  'promptAtAudioFileCreation'
                )
              "
              :show-reload="promptContentChanged"
              :generating-audio="generatingAudioForPrompt"
              :file="promptAudioFile"
            />
          </el-form-item>

          <el-form-item
            prop="watson_speech_node.data.user_input_nodes_setting.data.fallback_prompt_text"
            :label="__('Fallback Prompt')"
          >
            <input-variable-popper
              :value="fallbackPromptText"
              is-content-editable
              @input="updateText('fallback_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-watson_speech"
            />
            <audio-player
              class="audio-player"
              :disabled="!fallbackPromptText"
              @get-preview="
                generateAudio(
                  'fallbackPromptText',
                  'generatingAudioForFallbackPrompt',
                  'fallbackPromptAudioFile',
                  'fallbackPromptAtAudioFileCreation'
                )
              "
              :show-reload="fallbackPromptContentChanged"
              :generating-audio="generatingAudioForFallbackPrompt"
              :file="fallbackPromptAudioFile"
            />
          </el-form-item>

          <el-form-item
            prop="watson_speech_node.data.user_input_nodes_setting.data.no_input_prompt_text"
            :label="__('No Input Prompt')"
          >
            <input-variable-popper
              :value="noInputPromptText"
              is-content-editable
              @input="updateText('no_input_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-watson_speech"
            />
            <audio-player
              class="audio-player"
              :disabled="!noInputPromptText"
              @get-preview="
                generateAudio(
                  'noInputPromptText',
                  'generatingAudioForNoInputPrompt',
                  'noInputPromptAudioFile',
                  'noInputPromptAtAudioFileCreation'
                )
              "
              :show-reload="noInputPromptContentChanged"
              :generating-audio="generatingAudioForNoInputPrompt"
              :file="noInputPromptAudioFile"
            />
          </el-form-item>

          <el-form-item
            prop="watson_speech_node.data.user_input_nodes_setting.data.no_match_prompt_text"
            :label="__('No Match Prompt')"
          >
            <input-variable-popper
              :value="noMatchPromptText"
              is-content-editable
              @input="updateText('no_match_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scrollRef=".node-modal-watson_speech"
            />
            <audio-player
              class="audio-player"
              :disabled="!noMatchPromptText"
              @get-preview="
                generateAudio(
                  'noMatchPromptText',
                  'generatingAudioForNoMatchPrompt',
                  'noMatchPromptAudioFile',
                  'noMatchPromptAtAudioFileCreation'
                )
              "
              :show-reload="noMatchPromptContentChanged"
              :generating-audio="generatingAudioForNoMatchPrompt"
              :file="noMatchPromptAudioFile"
            />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Speech Recognizer')" name="sr_settings">
          <el-form-item :label="__('Assign transcribed text to variable')">
            <variable-dropdown
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :studioVariables="singleValuedVariables"
              :currentSelect="
                currentSelection(currentSelectionTranscribedTextToVariable)
              "
              :variableRule="transcribeTextRuleValue"
              @updateVariables="handleVariableUpdate"
              :nodeData="nodeToBind.watson_speech_node"
            />
          </el-form-item>
          <el-form-item :label="__('Assign confidence score to variable')">
            <variable-dropdown
              :placeholder="__('Select or create a new variable')"
              :new-item-message="__('new variable')"
              :studioVariables="singleValuedVariables"
              :currentSelect="
                currentSelection(currentSelectionConfidenceToVariable)
              "
              :variableRule="confidenceRuleValue"
              @updateVariables="handleVariableUpdate"
              :nodeData="nodeToBind.watson_speech_node"
            />
          </el-form-item>
          <el-form-item :label="__('Assign recording file to audio variable')">
            <create-or-select
              :items="audioVariables"
              label="variable_name"
              value="variable_id"
              :placeholder="__('Select or create a new audio variable')"
              :new-item-message="__('new variable')"
              :current_select="currentSelectionRecordFileToAudioVariable"
              @change="handleChangeRecordFileToAudioVariable"
            />
            <el-col :span="4">
              <span class="row-message" v-if="newVariableForAudioVar">{{
                __("new audio variable")
              }}</span>
            </el-col>
          </el-form-item>
          <el-form-item
            prop="watson_speech_node.data.language_id"
            :label="__('Language')"
          >
            <el-select
              v-model="nodeToBind.watson_speech_node.data.language_id"
              style="width: 100%;"
              :placeholder="__('Please select a language')"
              default-first-option
              filterable
              clearable
              v-loading="asrLanguagesLoading"
              :no-data-text="noText"
              :loading-text="__('fetching languages')"
            >
              <el-option
                v-for="(item, index) in filteredLanguages"
                :label="item.language_name"
                :value="item.language_id"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="watson_speech_node.data.user_input_nodes_setting.data.barge_in"
            :label="__('Speech Controls')"
          >
            <el-checkbox
              v-model="
                nodeToBind.watson_speech_node.data.user_input_nodes_setting.data
                  .barge_in
              "
              style="margin-left: 2px;"
              >{{ __("Barge In") }}
            </el-checkbox>
          </el-form-item>
          <el-form-item
            prop="watson_speech_node.data.user_input_nodes_setting.data.confidence_level"
            :label="__('Confidence Level')"
          >
            <el-input-number
              v-model="
                nodeToBind.watson_speech_node.data.user_input_nodes_setting.data
                  .confidence_level
              "
              :precision="2"
              :min="0.0"
              :step="0.05"
              :max="1"
              :step-strictly="false"
            ></el-input-number>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Event Handler')" name="event_handler">
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <el-form-item
                prop="watson_speech_node.data.user_input_nodes_setting.data.no_input_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noInputEventHandlerCanvas"
                  :count="
                    nodeToBind.watson_speech_node.data.user_input_nodes_setting
                      .data.no_input_count
                  "
                  :eventHandlerLabel="__('No Input Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no input event handler canvas')
                  "
                  @update-event-handler="setNoInputEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoInputEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
              <el-form-item
                prop="watson_speech_node.data.user_input_nodes_setting.data.no_match_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noMatchEventHandlerCanvas"
                  :count="
                    nodeToBind.watson_speech_node.data.user_input_nodes_setting
                      .data.no_match_count
                  "
                  :eventHandlerLabel="__('No Match Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no match event handler canvas')
                  "
                  @update-event-handler="setNoMatchEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoMatchEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Advanced ASR Settings')"
          name="advanced_asr_settings"
        >
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <advanced-speech-parameters
                :nlp-engine="'ibm_watson'"
                :speech-complete-timeout="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.speech_complete_timeout
                "
                :speech-incomplete-timeout="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.speech_incomplete_timeout
                "
                :no-input-timeout="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.no_input_timeout
                "
                :speech-start-timeout="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.speech_start_timeout
                "
                :inter-result-timeout="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.inter_result_timeout
                "
                :barge-in-sensitivity="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.barge_in_sensitivity
                "
                :auto-punctuation="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.auto_punctuation
                "
                :profanity-filter="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.profanity_filter
                "
                :single-utterance="
                  nodeToBind.watson_speech_node.data.user_input_nodes_setting
                    .data.single_utterance
                "
                @update-nlp-parameter="updateNlpParameters"
              >
              </advanced-speech-parameters>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Confirmation')" name="confirm_settings">
          <speech-input-confirmation
            @update-max-number-of-input-count="setMaxNumberOfInputCount($event)"
            :showNoInputTimeout="false"
            :documentRule="inputNodeSetting"
            :taskId="task_id"
            scroll-ref=".node-modal-watson_speech"
          />
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import AudioPlayer from "@/components/AudioPlayer";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers";
import AdvancedSpeechParameters from "@/components/AdvancedSpeechParameters.vue";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import { mapActions, mapGetters, mapState } from "vuex";
import SpeechInputConfirmation from "@/views/build/callflow/components/audioInputConfirmation/ConfirmationElement.vue";
import { eventHandlerNameValidation } from "@/utils/formValidationRules";
import { sanitizeInput } from "@/utils/promptText";
import VariableDropdown from "@/views/build/callflow/components/node-type-forms/components/VariableDropdown.vue";
import NodeUsesVariableDropdown from "@/views/build/callflow/components/node-type-forms/NodeUsesVariableDropdown.vue";

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false,
  variable_type: "audio"
};
const eventHandlerCanvasInitialize = {
  canvas_name: "",
  canvas_id: -1,
  msg: ""
};

export default {
  components: {
    InputVariablePopper,
    VariableDropdown,
    AudioPlayer,
    CreateOrSelect,
    EventHandlers,
    AdvancedSpeechParameters,
    SpeechInputConfirmation
  },
  mixins: [BaseNode, GenerateAudio, NodeUsesVariableDropdown],
  data() {
    const validateNoInputEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "input");
    };
    const validateNoMatchEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "match");
    };
    return {
      rules: {
        "watson_speech_node.data.user_input_nodes_setting.data.no_input_canvas_id": [
          {
            validator: validateNoInputEventHandler,
            trigger: "change"
          }
        ],
        "watson_speech_node.data.user_input_nodes_setting.data.no_match_canvas_id": [
          {
            validator: validateNoMatchEventHandler,
            trigger: "change"
          }
        ],
        "watson_speech_node.data.user_input_nodes_setting.data.prompt_text": [
          {
            required: true,
            message: __("Please add prompt text"),
            trigger: "blur"
          }
        ],

        "watson_speech_node.data.user_input_nodes_setting.data.fallback_prompt_text": [
          {
            required: true,
            message: __("Please add fallback prompt text"),
            trigger: "blur"
          }
        ],
        "watson_speech_node.data.language_id": [
          {
            required: true,
            message: __("Please select a language"),
            trigger: "blur"
          }
        ]
      },
      tabStructure: {
        tts_settings: [
          "watson_speech_node.data.user_input_nodes_setting.data.prompt_text",
          "watson_speech_node.data.user_input_nodes_setting.data.fallback_prompt_text"
        ],
        sr_settings: ["watson_speech_node.data.language_id"],
        event_handler: [
          "watson_speech_node.data.user_input_nodes_setting.data.no_input_canvas_id",
          "watson_speech_node.data.user_input_nodes_setting.data.no_match_canvas_id"
        ]
      },
      preText: "",
      promptAudioFile: "",
      fallbackPromptAudioFile: "",
      noInputPromptAudioFile: "",
      noMatchPromptAudioFile: "",
      promptAtAudioFileCreation: "",
      fallbackPromptAtAudioFileCreation: "",
      noInputPromptAtAudioFileCreation: "",
      noMatchPromptAtAudioFileCreation: "",
      transcribeTextRuleValue: "sys.watson_speech_transcribed_text_var",
      confidenceRuleValue: "sys.watson_speech_confidence_var",
      userUtteranceAudioRuleValue: "sys.watson_speech_audio_var",
      generatingAudioForPrompt: false,
      generatingAudioForFallbackPrompt: false,
      generatingAudioForNoInputPrompt: false,
      generatingAudioForNoMatchPrompt: false,
      activeTab: "tts_settings",
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      newVariableForTranscribedTextVar: 0,
      newVariableForConfidenceVar: 0,
      newVariableForAudioVar: 0,
      nodeName: "watson_speech_node",
      watsonSpeechNodeResponse: {
        ws_response_data: {
          raw_transcribe: "",
          confidence_score: "",
          additional_information: []
        },
        ws_response_meta: []
      }
    };
  },
  methods: {
    ...mapActions("prompts", {
      getPrompts: "getPrompts"
    }),
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),
    initializePrompts() {
      if (!this.prompts.length) {
        this.getPrompts();
      }
    },
    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 0 });
    },
    async checkASRLanguagesLength() {
      if (!this.asrLanguages.length && !this.asrLanguagesLoading) {
        this.$message({
          type: "error",
          // eslint-disable-next-line
          message: __("ASR languages are not provisioned in your business plan")
        });
      }
    },
    updateText(key, value) {
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        key,
        sanitizeInput(value)
      );
    },

    handleChangeRecordFileToAudioVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForAudioVar = _.isEmpty(option.msg) ? 0 : 1;
      const recordFileAudioVarObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "audio",
        msg: option.msg,
        rule_value: this.userUtteranceAudioRuleValue
      };
      if (_.isEmpty(this.nodeToBind.watson_speech_node.data.variable_rules)) {
        this.$set(
          this.nodeToBind.watson_speech_node.data,
          "variable_rules",
          {}
        );
        this.$set(
          this.nodeToBind.watson_speech_node.data.variable_rules,
          "data",
          []
        );
      } else {
        _.remove(
          this.nodeToBind.watson_speech_node.data.variable_rules.data,
          obj => obj.rule_value === this.userUtteranceAudioRuleValue
        );
      }
      this.nodeToBind.watson_speech_node.data.variable_rules.data.push(
        recordFileAudioVarObj
      );
    },
    setNoInputEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "watson_speech_node.data.user_input_nodes_setting.data.no_input_canvas_id"
      );
    },
    setNoMatchEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "watson_speech_node.data.user_input_nodes_setting.data.no_match_canvas_id"
      );
    },

    setNoMatchEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_match_count",
        val
      );
    },
    setNoInputEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_input_count",
        val
      );
    },
    setMaxNumberOfInputCount(val) {
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_max_number_of_input_count",
        val
      );
    },
    updateNlpParameters(nlp_param, value, default_value) {
      if (value !== false && value !== 0 && !value) {
        value = default_value;
      }
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        nlp_param,
        value
      );
    },
    arrayRule() {
      let arrayRule = _.find(
        this.getVariableRules,
        rule => rule.variable_name === this.arrayVariableName
      );
      let { variable_name, variable_id } = this.arrayVariable;
      if (!arrayRule) {
        arrayRule = {
          rule_value: "root",
          default_value: "",
          variable_name,
          variable_id,
          array_variable: true,
          variable_type: "array"
        };
        this.nodeToBind.watson_speech_node.data.variable_rules.data.push(
          arrayRule
        );
      }
      return arrayRule;
    },
    setWatsonSpeechNodeJsonResponse() {
      let rule = this.arrayRule();
      rule.default_value = JSON.stringify(this.watsonSpeechNodeResponse);
    },
    cleanUpNodeToPrepareForSubmit() {
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      let filteredVariableRulesData = filterRowsIfSomeKeyValueIsAbsent(
        this.nodeToBind.watson_speech_node.data.variable_rules.data,
        "variable_name"
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.variable_rules,
        "data",
        []
      );
      this.nodeToBind.watson_speech_node.data.variable_rules.data = filteredVariableRulesData;
      this.setWatsonSpeechNodeJsonResponse();
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    }
  },
  arrayRule() {
    let arrayRule = _.find(
      this.getVariableRules,
      rule => rule.variable_name === this.arrayVariableName
    );
    let { variable_name, variable_id } = this.arrayVariable;
    if (!arrayRule) {
      arrayRule = {
        rule_value: "root",
        default_value: "",
        variable_name,
        variable_id,
        array_variable: true,
        variable_type: "array"
      };
      this.nodeToBind.watson_speech_node.data.variable_rules.data.push(
        arrayRule
      );
    }
    return arrayRule;
  },
  createOrEditNode() {
    if (!this.isTaskReadOnly) {
      this.attemptedToSubmit = true;
      this.newVariableCreated = _.find(
        this.nodeToBind.watson_speech_node.data.variable_rules.data,
        rule => rule.variable_id === -1
      );
      this.$refs.nodeForm.validate((valid, errors) => {
        if (valid) {
          this.process({
            node: this.nodeToBind,
            nodeInContext: this.node
          })
            .then(async () => {
              this.newVariableCreated ? await this.forceFetchVariables() : null;
              this.setClickedNode(null);
              this.toggleNodeSubmit(false);
            })
            .catch(() => {
              this.toggleNodeSubmit(false);
            });
        } else {
          let errMessages = _.flatten(
            _.map(errors, err => {
              return _.map(err, "message");
            })
          );

          _.map(errMessages, message => {
            setTimeout(() => this.errorNotification(message), 100);
          });
          this.toggleNodeSubmit(false);
          let errorPropName = Object.keys(errors);
          let findTab = _.findKey(this.tabStructure, function(structure) {
            return structure.some(r => errorPropName.includes(r));
          });

          if (!_.isEmpty(findTab)) {
            this.activeTab = findTab;
          }
          return false;
        }
      });
    }
  },
  computed: {
    ...mapState("prompts", {
      prompts: state => state.prompts,
      promptsLoading: state => state.loading
    }),
    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList"
    }),
    ...mapGetters("variables", {
      arrayVariable: "arrayVariable",
      arrayVariableName: "arrayVariableName",
      singleValuedVariables: "singleValuedVariables"
    }),
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages,
      asrLanguagesLoading: state => state.loading
    }),
    inputNodeSetting: {
      get: function() {
        return _.get(
          this.nodeToBind.watson_speech_node.data,
          "user_input_nodes_setting",
          {}
        );
      },
      set: function(value) {
        this.$set(
          this.nodeToBind.watson_speech_node.data,
          "user_input_nodes_setting",
          value
        );
      }
    },
    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    },
    fallbackPromptContentChanged() {
      return this.fallbackPromptText !== this.fallbackPromptAtAudioFileCreation;
    },
    noInputPromptContentChanged() {
      return this.noInputPromptText !== this.noInputPromptAtAudioFileCreation;
    },
    noMatchPromptContentChanged() {
      return this.noMatchPromptText !== this.noMatchPromptAtAudioFileCreation;
    },
    getOptionsInitiators() {
      return ["{{", "[["];
    },
    noInputEventHandlerCanvas() {
      return this.nodeToBind.watson_speech_node.data.user_input_nodes_setting
        .data.no_input_canvas_id;
    },
    noMatchEventHandlerCanvas() {
      return this.nodeToBind.watson_speech_node.data.user_input_nodes_setting
        .data.no_match_canvas_id;
    },
    promptText() {
      return this.nodeToBind.watson_speech_node.data.user_input_nodes_setting
        .data.prompt_text;
    },
    fallbackPromptText() {
      return this.nodeToBind.watson_speech_node.data.user_input_nodes_setting
        .data.fallback_prompt_text;
    },
    noInputPromptText() {
      return sanitizeInput(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data
          .no_input_prompt_text
      );
    },
    noMatchPromptText() {
      return sanitizeInput(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data
          .no_match_prompt_text
      );
    },
    currentSelectionTranscribedTextToVariable() {
      return (
        _.find(
          this.nodeToBind.watson_speech_node.data.variable_rules.data,
          rule => rule.rule_value === this.transcribeTextRuleValue
        ) ||
        _.find(
          this.nodeToBind.watson_speech_node.data.interaction_variable_rules
            .data,
          rule => rule.rule_value === this.transcribeTextRuleValue
        ) ||
        this.variableRuleInitialize
      );
    },
    currentSelectionConfidenceToVariable() {
      return (
        _.find(
          this.nodeToBind.watson_speech_node.data.variable_rules.data,
          rule => rule.rule_value === this.confidenceRuleValue
        ) ||
        _.find(
          this.nodeToBind.watson_speech_node.data.interaction_variable_rules
            .data,
          rule => rule.rule_value === this.confidenceRuleValue
        ) ||
        this.variableRuleInitialize
      );
    },
    currentSelectionRecordFileToAudioVariable() {
      let variableRules = _.find(
        this.nodeToBind.watson_speech_node.data.variable_rules.data,
        rule => rule.rule_value === this.userUtteranceAudioRuleValue
      );
      const { variable_id, variable_name } = _.isEmpty(variableRules)
        ? this.variableRuleInitialize
        : variableRules;
      return variable_id === -1 ? variable_name : variable_id;
    },

    filteredLanguages() {
      let asrLanguages = [];
      let self = this;
      asrLanguages = _.filter(this.asrLanguages, function(o) {
        return o.language_provider === "ibm_watson";
      });
      self.preText = "Watson Speech";
      return asrLanguages;
    },
    noText() {
      // eslint-disable-next-line
      return __(":preText ASR languages are not provisioned in your business plan", {
          preText: this.preText
        }
      );
    },
    transferPhoneNumber: {
      get() {
        return (
          this.nodeToBind.watson_speech_node.data.property_rules.data
            .transfer_phone_number || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.watson_speech_node.data.property_rules.data.transfer_phone_number = expression;
      }
    },

    ani: {
      get() {
        return (
          this.nodeToBind.watson_speech_node.data.property_rules.data.ani ||
          "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.watson_speech_node.data.property_rules.data.ani = expression;
      }
    },

    displayName: {
      get() {
        return (
          this.nodeToBind.watson_speech_node.data.property_rules.data
            .display_name || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.watson_speech_node.data.property_rules.data.display_name = expression;
      }
    }
  },
  async created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.watson_speech_node)
    ) {
      this.$set(this.nodeToBind, "watson_speech_node", {});
      this.$set(this.nodeToBind.watson_speech_node, "data", {});
      this.$set(this.nodeToBind.watson_speech_node.data, "language_id", null);
      this.$set(this.nodeToBind.watson_speech_node.data, "variable_rules", {});
      this.$set(
        this.nodeToBind.watson_speech_node.data,
        "interaction_variable_rules",
        {}
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.variable_rules,
        "data",
        []
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.interaction_variable_rules,
        "data",
        []
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data,
        "user_input_nodes_setting",
        {
          ...SpeechInputConfirmation.default_value
        }
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting,
        "data",
        {}
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_match_count",
        2
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_input_count",
        2
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "fallback_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_input_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_match_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "confidence_level",
        0.5
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "override_form_nlp_params",
        false
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "speech_complete_timeout",
        1000
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "speech_incomplete_timeout",
        20000
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "no_input_timeout",
        5000
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "speech_start_timeout",
        50
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "inter_result_timeout",
        0
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "barge_in_sensitivity",
        0.5
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "auto_punctuation",
        false
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "profanity_filter",
        false
      );
      this.$set(
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
        "single_utterance",
        true
      );
      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.WATSON_SPEECH.NODE_TYPE
      );
    } else {
      if (
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data
          .no_input_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
          "no_input_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noInputEventHandlerCanvas
          })
        );
      }

      if (
        this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data
          .no_match_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.watson_speech_node.data.user_input_nodes_setting.data,
          "no_match_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noMatchEventHandlerCanvas
          })
        );
      }

      if (
        !_.has(
          this.nodeToBind.watson_speech_node.data,
          "user_input_nodes_setting"
        )
      ) {
        this.$set(
          this.nodeToBind.watson_speech_node.data,
          "user_input_nodes_setting",
          {}
        );
        this.$set(
          this.nodeToBind.watson_speech_node.data.user_input_nodes_setting,
          "data",
          {
            ...SpeechInputConfirmation.default_value
          }
        );
      }
    }
    await this.initializeLanguages();
    this.initializePrompts();
    await this.checkASRLanguagesLength();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    /*&:focus {*/
    /*  border: 1px solid black;*/
    /*}*/
  }
}
</style>
